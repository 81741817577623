import * as React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { PrismicRichText } from "@prismicio/react";
import { Link, graphql } from "gatsby";
import { StaticImage, Image } from "gatsby-plugin-image";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";
import { MdLocationOn } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { AiTwotoneMessage, AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Slider from "../components/common/Slider";
import {
  Container,
  Row,
  SectionTitle,
  Column,
  FlexContainer,
  Button,
} from "../components/common";

import GetInTouchImage from "../images/get_in_touch.png";
import ContactForm from "../components/contactForm";

export const query = graphql`
  query contactPageQuery($lang: String) {
    prismicMisc(lang: { eq: $lang }) {
      data {
        room
        home
        contact
      }
    }
    prismicContact(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        call_us
        get_in_touch
        location
        tel
        message
        email
        facebook

        send_a_message
        send_us_a_message
        subject
        message1
        your_name
        your_email
        thanks_for_request

        body {
          ... on PrismicContactDataBodyOgInfo {
            primary {
              ogDescription: description
              ogTitle: title
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Section = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.paleGrey};
`;

const Icon = styled.span`
  font-size: 1rem;
  color: #fff;
  margin-right: 4px;
`;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin: 0 4px 20px;
`;

const ButtonContainer = styled(FlexContainer)`
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
`;

const CallUs = styled.div`
  background: #f5ece6;
  text-align: center;

  padding-top: 16px;
  padding-bottom: 16px;

  @media (min-width: 992px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const LowerPart = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const GetInTouch = styled.div`
  background-color: ${props => props.theme.colors.orange};
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 25px 20px 25px 30px;
  width: 100%;
  color: #fff;

  @media (min-width: 992px) {
    width: 400px;
  }
`;

const GetInTouchTitle = styled.h3`
  color: #fff;
  font-size: 25px;
  line-height: 27px;
  font-weight: 300;
  letter-spacing: 0px;
`;

const ContactFormContainer = styled.div`
  flex-grow: 1;
  margin-top: 16px;
  @media (min-width: 992px) {
    width: 650px;
    flex: none;
    margin-top: 0;
  }
`;

const StyledContainer = styled(Container)`
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const InfoRow = ({ icon, text }) => {
  return (
    <IconContainer as="div">
      <Icon as={icon}></Icon>
      <span>{text}</span>
    </IconContainer>
  );
};

const ContactPage = ({ data: prismicData, pageContext }) => {
  const { data } = prismicData.prismicContact;
  const seo = data.body[0].primary;

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 22.28571862744277,
    lng: 114.15182356425291,
  };

  const markerCenter = {
    ...center,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBFMouIYPlvN2CzpHbAx2tW4D46N-y0DfI",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Layout lang={pageContext.lang} misc={prismicData.prismicMisc.data}>
      <Seo
        title={seo.ogTitle}
        description={seo.ogDescription}
        ogImageUrl={seo.image.url}
      />
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={19}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <Marker position={markerCenter}></Marker>
        </GoogleMap>
      ) : (
        <></>
      )}
      <CallUs>
        <h2>{data.call_us}</h2>
      </CallUs>
      <Section>
        <StyledContainer>
          <LowerPart>
            <GetInTouch>
              <GetInTouchTitle>{data.get_in_touch}</GetInTouchTitle>
              <InfoRow icon={MdLocationOn} text={data.location}></InfoRow>
              <InfoRow icon={BsTelephoneFill} text={data.tel}></InfoRow>
              <InfoRow icon={AiTwotoneMessage} text={data.message}></InfoRow>
              <InfoRow icon={AiOutlineMail} text={data.email}></InfoRow>
              <InfoRow icon={FaFacebookF} text={data.facebook}></InfoRow>
            </GetInTouch>
            <ContactFormContainer>
              <ContactForm data={prismicData} />
            </ContactFormContainer>
            {/* <ImageContainer>
              <StaticImage src="../images/1.jpg" alt="Lemanndi" />
            </ImageContainer> */}
          </LowerPart>
        </StyledContainer>
      </Section>
    </Layout>
  );
};

export default withPrismicPreview(ContactPage);

import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Input, Textarea, Button } from "./common";
import styled from "styled-components";

import { Column } from "./common";

const InputCell = styled.div`
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  height: 100px;
`;

const StyledButton = styled(Button)`
  border: 0;
  border-radius: 5px;
  padding: 10px 20px;
`;

const Title = styled.h4`
  margin-top: 0;
  margin-bottom: 16px;
`;

const ContactForm = ({ data }) => {
  const [state, handleSubmit] = useForm("xvolweze");

  console.log(data);
  const d = data.prismicContact.data;
  if (state.succeeded) {
    return <p className="big">{d.thanks_for_request}</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Title>{d.send_us_a_message}</Title>
      <Row>
        <Column size={2} gap="20px">
          <InputCell>
            <StyledInput
              id="name"
              type="text"
              name="name"
              placeholder={d.your_name}
            />
            <ValidationError
              prefix="Your Name"
              field="name"
              errors={state.errors}
            />
          </InputCell>
        </Column>
        <Column size={2} gap="20px">
          <InputCell>
            <label htmlFor="email"></label>
            <StyledInput
              id="email"
              type="email"
              name="email"
              placeholder={d.your_email}
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </InputCell>
        </Column>
      </Row>
      <StyledInput id="subject" name="subject" placeholder={d.subject} />
      <StyledTextarea id="message" name="message" placeholder={d.message1} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <StyledButton type="submit" disabled={state.submitting} color="dark">
        {d.send_a_message}
      </StyledButton>
    </form>
  );
};

export default ContactForm;
